<template>
  <div class="row">
    <div class="col-12 g" v-if="devices.length">
      <div class="alert alert-danger g" style="padding: 20px">
        <h5>هناك اجهزة بصمة غير متصلة وقد تؤثر على التقارير:</h5>
        <ul>
          <li v-for="(device, index) in devices" :key="index">
            اسم الجهاز: {{ device.title }}
            <br />
            الرقم التسلسلي: {{ device.serial_number }}
            <br />
            آخر نشاط: {{ device.last }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="col-12 col-lg-3"
      @click="go('/teachers')"
      style="cursor: pointer"
    >
      <statistic-card-horizontal
        icon="UsersIcon"
        :statistic="info.teachers ? info.teachers : 0"
        statistic-title="عدد المعلمين لديك"
      />
    </div>
    <div
      class="col-12 col-lg-3"
      @click="go('/fingerprint')"
      style="cursor: pointer"
    >
      <statistic-card-horizontal
        icon="ServerIcon"
        :statistic="info.devices ? info.devices : 0"
        statistic-title="عدد أجهزة البصمة لديك"
      />
    </div>
    <div
      class="col-12 col-lg-3"
      @click="go('/reports')"
      style="cursor: pointer"
    >
      <statistic-card-horizontal
        icon="UserCheckIcon"
        :statistic="info.attends ? info.attends : 0"
        statistic-title="عدد الحضور اليوم"
      />
    </div>
    <div
      class="col-12 col-lg-3"
      @click="go('/reports')"
      style="cursor: pointer"
    >
      <statistic-card-horizontal
        icon="WatchIcon"
        :statistic="info.lates ? info.lates : 0"
        statistic-title="عدد التأخر اليوم"
      />
    </div>
    <div class="col-12"></div>
    <div class="col-12 g">
      <h4 class="text-muted">
        <feather-icon icon="FileIcon" size="20" /> نذبة عن تقارير
        <strong><u>اليوم</u></strong>
      </h4>
    </div>
    <div class="col-12">
      <div class="alert-warning text-center g" v-if="info.freeday">
        لم يتم الفحص اليوم
      </div>
      <div class="row" v-if="!info.freeday">
        <div class="col-12 col-lg-4 g">
          <div class="card">
            <div class="card-header">
              <h4>الحضور ({{ info.attends ? info.attends : 0 }})</h4>
            </div>
            <div class="card-body">
              <div class="col-12 table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr
                      v-for="teacher in info.attends_list"
                      :key="teacher.name"
                    >
                      <td>
                        {{ teacher.name }}
                        <br />
                        <small> {{ teacher.time }}</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card">
            <div class="card-header">
              <h4>التأخر ({{ info.lates ? info.lates : 0 }})</h4>
            </div>
            <div class="card-body">
              <div class="col-12 table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr v-for="teacher in info.lates_list" :key="teacher.name">
                      <td>
                        {{ teacher.name }}
                        <br />

                        <small
                          >{{ teacher.time }}
                          <span class="badge bg-danger"
                            >{{ teacher.late_time }} دقيقة</span
                          ></small
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card">
            <div class="card-header">
              <h4>الغياب ({{ info.ghiabs ? info.ghiabs : 0 }})</h4>
            </div>
            <div class="card-body">
              <div class="col-12 table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr v-for="teacher in info.ghiab_list" :key="teacher.name">
                      <td>
                        {{ teacher.name }}
                        <br />
                        <span class="text-danger">غائب</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { BCard, BCardText, BLink } from "bootstrap-vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    StatisticCardHorizontal,
  },
  data() {
    return {
      info: { attends_list: [], lates_list: [], ghiab_list: [] },
      devices: [],
    };
  },
  methods: {
    go(url) {
      this.$router.push(url);
    },
  },
  created() {
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/reports/stats", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (data) {
        _g.info = data.data.response;
        if (data.data.status == 200) {
          alert("حدث مشكلة اثناء الاتصال بالخادم.");
        }
      })
      .catch(function (error) {
        alert("حدث مشكلة اثناء الاتصال بالخادم");
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/devices", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (data) {
        _g.devices = data.data.response;
        if (data.data.status == 200) {
          alert("حدث مشكلة اثناء الاتصال بالخادم.");
        }
      })
      .catch(function (error) {
        alert("حدث مشكلة اثناء الاتصال بالخادم");
      });
  },
};
</script>

<style>
</style>
